import './hero.scss';
import classNames from 'classnames';

const numGray = 0;
const numBlue = 0;
const numYellow = 60;
const numGreen = 0;
const numRed = 0;
const numWhite = 60;
// const numTotal = numGray + numBlue + numYellow + numGreen + numRed + numWhite;

function Hero() {
  return (
    <div
      id="scene"
      className={classNames('absolute h-screen w-screen opacity-90')}>
      <div className={classNames('shapes')}>
        {Array.from(Array(numGray), (_, i) => (
          <div key={i} className={classNames('shape', 'gray')} />
        ))}
        {Array.from(Array(numBlue), (_, i) => (
          <div key={i} className={classNames('shape', 'blue')} />
        ))}
        {Array.from(Array(numYellow), (_, i) => (
          <div key={i} className={classNames('shape', 'yellow')} />
        ))}
        {Array.from(Array(numGreen), (_, i) => (
          <div key={i} className={classNames('shape', 'green')} />
        ))}
        {Array.from(Array(numRed), (_, i) => (
          <div key={i} className={classNames('shape', 'red')} />
        ))}
        {Array.from(Array(numWhite), (_, i) => (
          <div key={i} className={classNames('shape', 'white')} />
        ))}
      </div>
    </div>
  );
}

export default Hero;
