function Logo({ logoBaseSize, className, style }) {
  return (
    <svg
      className={className}
      style={style}
      width={logoBaseSize}
      height={logoBaseSize}
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="dropShadow" x="-50%" y="-50%" width="200%" height="200%">
          <feOffset result="offOut" in="SourceAlpha" dx="-7.373" dy="10.65" />
          <feGaussianBlur result="blurOut" stdDeviation="11" />
          <feFlood flood-color="black" flood-opacity="0.25" />
          <feComposite in2="blurOut" operator="in" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="insetShadow">
          <feOffset dx="1.639" dy="-1.639" />
          <feGaussianBlur stdDeviation="3" result="offset-blur" />
          <feComposite
            operator="out"
            in="SourceGraphic"
            in2="offset-blur"
            result="inverse"
          />
          <feFlood flood-color="black" flood-opacity="0.25" result="color" />
          <feComposite operator="in" in="color" in2="inverse" result="shadow" />
          <feComposite operator="over" in="shadow" in2="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#dropShadow)">
        <g filter="url(#insetShadow)">
          <path
            d="M208.149 40.0745L87.9255 80.1489L128 120.223L208.149 40.0745Z"
            fill="#FFE066"
          />
          <path
            d="M40.0745 47.8511L80.1489 168.074L120.223 128L40.0745 47.8511Z"
            fill="#AAACB3"
          />
          <path
            d="M47.851 215.926L168.074 175.851L128 135.777L47.851 215.926Z"
            fill="#FFE066"
          />
          <path
            d="M215.926 208.149L175.851 87.9256L135.777 128L215.926 208.149Z"
            fill="#AAACB3"
          />{' '}
        </g>
      </g>
    </svg>
  );
}

export default Logo;
