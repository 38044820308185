
function CallIcon({ size = "h-4 w-4", fill = "fill-gray-light" }) {
  return (
    <svg className={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={fill} d="M19.475 22.275C17.2084 22.275 15.0084 21.7707 12.875 20.762C10.7417 19.754 8.84605 18.4207 7.18805 16.762C5.52938 15.104 4.19605 13.2083 3.18805 11.075C2.17938 8.94167 1.67505 6.74167 1.67505 4.475C1.67505 3.94167 1.85005 3.5 2.20005 3.15C2.55005 2.8 2.99172 2.625 3.52505 2.625H7.55005C8.16672 2.625 8.66672 2.775 9.05005 3.075C9.43338 3.375 9.69172 3.80833 9.82505 4.375L10.45 7.35C10.55 7.85 10.546 8.28733 10.438 8.662C10.3294 9.03733 10.1084 9.36667 9.77505 9.65L7.20005 11.9C7.76672 12.8 8.45005 13.646 9.25005 14.438C10.05 15.2293 10.9084 15.9167 11.825 16.5L14.35 14.05C14.6834 13.7333 15.0544 13.5167 15.463 13.4C15.871 13.2833 16.3167 13.2833 16.8 13.4L19.5751 14.025C20.1584 14.175 20.5961 14.425 20.8881 14.775C21.1794 15.125 21.3251 15.5917 21.3251 16.175V20.4C21.3251 20.9333 21.15 21.379 20.8 21.737C20.45 22.0957 20.0084 22.275 19.475 22.275Z" />
    </svg>
  );
}

export default CallIcon;
