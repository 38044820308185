import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import { PrimaryButton } from './Button';
import { icons } from './projects';

function Project({ thumbnail, title, description, tags = [], links = {} }) {
  const [isHover, setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const myRef = useRef(null);

  return (
    <div
      ref={myRef}
      className={classNames(
        'h-fit w-3/4 sm:h-56',
        'rounded-2xl',
        'drop-shadow-xl',
        'overflow-hidden',
        'flex flex-col sm:flex-row',
        'm-auto',
        'bg-gray',
        'hover:ring-8',
        'focus-visible:ring-8',
        'ring-blue',
      )}
      onClick={() => {
        myRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'end',
          inline: 'center',
        });
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}>
      <React.Fragment>
        <div
          className={classNames(
            'relative h-48 w-full sm:h-full sm:w-1/2',
            'overflow-hidden',
            'bg-yellow',
          )}>
          {thumbnail ? (
            <img
              className={classNames(
                'absolute h-full sm:h-auto left-1/2 top-1/2',
                'translate-x-[-50%] translate-y-[-50%]',
              )}
              src={thumbnail}
              alt={title}
            />
          ) : (
            <div className={classNames('h-full', 'bg-gray-light')}> </div>
          )}
        </div>
        <div
          className={classNames(
            'flex flex-col',
            'w-full sm:h-full sm:w-1/2',
            'p-8',
          )}>
          <div
            className={classNames(
              'flex flex-wrap',
              'top-0 right-0',
              'font-body font-medium',
              'text-xs text-gray-light',
            )}>
            {tags.map((t) => (
              <div
                key={t}
                className={classNames(
                  'w-fit',
                  'box-border px-2 py-1 mb-3 mr-[10px]',
                  'outline-dashed outline-gray-light rounded-md',
                )}>
                {t}
              </div>
            ))}
          </div>
          <div
            className={classNames('bottom-0 left-0', 'mt-auto', 'text-left')}>
            <div
              className={classNames(
                'font-title font-normal',
                'text-3xl sm:text-4xl text-yellow',
              )}>
              {title}
            </div>
            <p
              className={classNames(
                'text-ellipsis',
                'font-body font-normal',
                'text-sm sm:text-base text-gray-light',
              )}>
              {description}
            </p>
          </div>
        </div>
        {(isHover || isFocus) && (
          <div
            className={classNames(
              'absolute flex',
              // "absolute flex flex-row-reverse flex-wrap-reverse",
              'bottom-0 right-0',
              'm-4',
              'space-x-2',
              'font-body font-bold',
              'text-base text-gray-light',
            )}>
            {Object.keys(links).map((key) => (
              <PrimaryButton
                key={key}
                text={key}
                icon={icons[key]}
                link={links[key]}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    </div>
  );
}

export default Project;
