import classNames from 'classnames';

import goTo from './goTo';

function Button({ text, onClick, link, icon, isRound, classes }) {
  const isLinkAnchorRequired =
    link &&
    (link.startsWith('sms:') ||
      link.startsWith('tel:') ||
      link.startsWith('mailto:'));
  const button = (
    <button
      key={text}
      className={classNames(
        {
          flex: icon,
          'h-8 w-fit rounded-2xl': !isRound,
          'h-10 w-10 rounded-3xl': isRound,
        },
        'box-border',
        'space-x-1',
        'font-body font-bold',
        'text-base leading-4',
        ...classes,
      )}
      onClick={(e) => {
        e.stopPropagation();
        !isLinkAnchorRequired && link && goTo(link);
        onClick && onClick();
      }}>
      {icon}
      {!isRound && <span>{text}</span>}
    </button>
  );

  return isLinkAnchorRequired ? <a href={link}>{button}</a> : button;
}

export function PrimaryButton(props) {
  return (
    <Button
      {...props}
      icon={
        props.icon && (
          <props.icon
            size={props.isRound ? 'h-5 w-5' : 'h-4 w-4'}
            fill="fill-gray-light"
          />
        )
      }
      classes={[
        'bg-blue',
        {
          'px-3.5 py-2': !props.isRound,
          'p-2.5': props.isRound,
        },
        'text-gray-light',
        'hover:ring-[3px] ring-gray-light',
        'focus-visible:ring-[3px]',
        'drop-shadow-lg',
        props.className,
      ]}
    />
  );
}

export function SecondaryButton(props) {
  return (
    <Button
      {...props}
      icon={
        props.icon && (
          <props.icon
            size={props.isRound ? 'h-5 w-5' : 'h-4 w-4'}
            fill="fill-yellow group-hover:fill-gray-dark group-focus-visible:fill-gray-dark"
          />
        )
      }
      classes={[
        'group',
        'border-solid border-2 border-yellow',
        {
          'px-3 py-1.5': !props.isRound,
          'p-2': props.isRound,
        },
        'text-yellow',
        'hover:bg-yellow hover:text-gray-dark',
        'focus-visible:bg-yellow focus-visible:text-gray-dark',
        props.className,
      ]}
    />
  );
}

export default Button;
