import { useState } from 'react';
import classNames from 'classnames';

import useScrollPosition from './useScrollPosition';
import Logo from './logos/Logo';
import useMediaQuery from './useMediaQuery';
import Contact from './Contact';
import { PrimaryButton, SecondaryButton } from './Button';
import SupportIcon from './icons/SupportIcon';
import ContactIcon from './icons/ContactIcon';
import DownwardArrowIcon from './icons/DownwardArrowIcon';

function lerp(v0, v1, t) {
  const v = v0 * (1 - t) + v1 * t;

  return v1 > v0 ? Math.min(v, v1) : Math.max(v, v1);
}

function Header({ windowHeight, minimizedHeaderHeight, onClickPortfolio }) {
  const [isContactOpen, setIsContactOpen] = useState(false);

  const isSm = useMediaQuery('(max-width: 640px)');

  const scrollPosition = useScrollPosition();
  const percentage = scrollPosition / (windowHeight - minimizedHeaderHeight);

  function lerpHeaderValue(a, b, t = percentage) {
    return lerp(a, b, t);
  }

  const headerTranslateY = lerpHeaderValue(
    0,
    windowHeight - minimizedHeaderHeight,
  );
  const leftMarginPercent = lerpHeaderValue(50, 0);

  const logoBaseSize = minimizedHeaderHeight * (isSm ? 2 : 4);
  const logoScale = lerpHeaderValue(1, minimizedHeaderHeight / logoBaseSize);
  const logoRotation = lerpHeaderValue(0, 180);
  const logoTopMargin = lerpHeaderValue(2 * minimizedHeaderHeight, 0);

  const textTopMargin = lerpHeaderValue(
    2 * minimizedHeaderHeight + logoBaseSize,
    4,
  );
  const textLeftMargin = lerpHeaderValue(0, -minimizedHeaderHeight / 2);
  const textScale = lerpHeaderValue(1, 0.5);

  const portfolioNavOpacity = lerpHeaderValue(100, 0, 2 * percentage);

  return (
    <header className={classNames('sticky w-full', 'z-10')}>
      <Contact isOpen={isContactOpen} onClose={() => setIsContactOpen(false)} />
      <div
        className={classNames(
          'fixed h-full w-full',
          'flex',
          'drop-shadow-md',
          'bg-gray-dark',
          'origin-top',
        )}
        style={{
          transform: `translateY(-${headerTranslateY}px)`,
        }}>
        <div
          className={classNames(
            'm-auto mb-8',
            'flex flex-col',
            'text-center',
            {},
          )}
          style={{
            opacity: `${portfolioNavOpacity}%`,
          }}>
          <span
            className={classNames(
              'font-title font-light',
              'text-2xl	sm:text-5xl text-gray-light',
              'drop-shadow-md',
            )}>
            portfolio
          </span>
          <div className="m-auto mt-2 sm:mt-4">
            <PrimaryButton
              text="portfolio"
              icon={DownwardArrowIcon}
              onClick={onClickPortfolio}
              isRound
            />
          </div>
        </div>
      </div>
      <Logo
        logoBaseSize={logoBaseSize}
        className={classNames('fixed', 'm-auto', 'origin-top-left', {})}
        style={{
          transform: `scale(${logoScale}) translateX(${leftMarginPercent}vw) translateX(-${leftMarginPercent}%) translateY(${logoTopMargin}px) translate(50%, 50%) rotate(${logoRotation}deg) translate(-50%, -50%)`,
        }}
      />
      <div
        className={classNames(
          'fixed',
          'font-title font-normal',
          'text-6xl text-gray-light text-center',
          // "origin-left",
          {},
        )}
        style={{
          transform: `scale(${textScale}) translateX(${leftMarginPercent}vw) translateX(-${leftMarginPercent}%) translateX(${textLeftMargin}px) translateY(${textTopMargin}px)`,
        }}>
        recreational<span className="text-yellow">coder</span>
      </div>
      <div
        className={classNames(
          'flex',
          // "invisible sm:visible",
          'fixed right-0',
          'mt-3 sm:m-4 mr-4 sm:mr-6',
          'space-x-3 sm:space-x-2',
        )}>
        <SecondaryButton
          text="support"
          icon={SupportIcon}
          link="https://www.patreon.com/recreationalcoder"
          isRound={isSm}
        />
        <PrimaryButton
          text="contact"
          icon={ContactIcon}
          onClick={() => setIsContactOpen(true)}
          isRound={isSm}
        />
      </div>
    </header>
  );
}

export default Header;
