import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { otherChannels, primaryChannels } from './channels';
import { PrimaryButton, SecondaryButton } from './Button';
import useMediaQuery from './useMediaQuery';

function Contact({ isOpen, onClose }) {
  const isSm = useMediaQuery('(max-width: 640px)');

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-dark bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={classNames(
                  'relative transform overflow-hidden',
                  'rounded-2xl',
                  'border-solid border-8 border-gray-light',
                  'bg-gray',
                  'text-left',
                  'shadow-xl',
                  'transition-all',
                  'w-full max-w-xs sm:max-w-2xl',
                )}>
                <div className={classNames('p-8 space-y-8')}>
                  <div
                    className={classNames(
                      'bottom-0 left-0',
                      'mt-auto',
                      'text-center sm:text-left',
                    )}>
                    <div
                      className={classNames(
                        'font-title font-normal',
                        'text-3xl sm:text-4xl text-yellow',
                      )}>
                      Let's Work Together
                    </div>
                    <p
                      className={classNames(
                        'text-ellipsis',
                        'font-body font-normal',
                        'text-sm sm:text-base text-gray-light',
                      )}>
                      Come see what we are up to on social media, ask us any
                      questions you have, or let us know if you've got a project
                      that seems up our alley! We would love to hear from you.
                    </p>
                  </div>
                  <div
                    className={classNames(
                      'flex flex-col items-center sm:items-end',
                      'bottom-0 right-0',
                      'space-y-4',
                      'font-body font-bold',
                      'text-base',
                    )}>
                    <div
                      className={classNames(
                        'flex flex-wrap justify-center sm:justify-end',
                        'max-w-[80%]',
                        'space-x-2',
                      )}>
                      {otherChannels.map((channel) => (
                        <SecondaryButton
                          key={channel.name}
                          className={classNames('mb-2')}
                          text={channel.name}
                          icon={channel.icon}
                          link={channel.link}
                          isRound={isSm}
                        />
                      ))}
                    </div>
                    <div className={classNames('flex', 'space-x-2')}>
                      {primaryChannels.map((channel) => (
                        <PrimaryButton
                          key={channel.name}
                          text={channel.name}
                          icon={channel.icon}
                          link={channel.link}
                          isRound={isSm}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Contact;
