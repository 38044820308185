import CallIcon from "./icons/CallIcon";
import ChatIcon from "./icons/ChatIcon";
import MailIcon from "./icons/MailIcon";
import InstagramIcon from "./icons/InstagramIcon";
import TwitterIcon from "./icons/TwitterIcon";
import PatreonIcon from "./icons/PatreonIcon";
import GithubIcon from "./icons/GithubIcon";
import LinkedInIcon from "./icons/LinkedInIcon";

export const primaryChannels = [
  {
    name: "call",
    icon: CallIcon,
    link:"tel: 8176812939",
  },
  {
    name: "text",
    icon: ChatIcon,
    link:"sms: 8176812939",
  },
  {
    name: "email",
    icon: MailIcon,
    link:"mailto: shahman@recreationalcoder.dev",
  },
];

export const otherChannels = [
  {
    name: "instagram",
    icon: InstagramIcon,
    link:"https://www.instagram.com/recreationalcoder/",
  },
  {
    name: "twitter",
    icon: TwitterIcon,
    link:"https://twitter.com/recoderdev",
  },
  {
    name: "patreon",
    icon: PatreonIcon,
    link:"https://www.patreon.com/recreationalcoder",
  },
  {
    name: "github",
    icon: GithubIcon,
    link:"https://github.com/recreationalcode",
  },
  {
    name: "linkedin",
    icon: LinkedInIcon,
    link:"https://www.linkedin.com/company/recreationalcoder",
  },
];