import AustinLymphaticThumbnail from './images/Austin Lymphatic Thumbnail.png';
import CrystalThumbnail from './images/Crystal Thumbnail.png';
import DisappearThumbnail from './images/Disappear Thumbnail.png';
import EarthackThumbnail from './images/Earthack Coin Thumbnail.jpg';
import EbbFlowThumbnail from './images/E&F Logo.png';
import EmailOpenProjectThumbnail from './images/Email Open Project Thumbnail.png';
import FloatOnThumbnail from './images/Float On Thumbnail.jpg';
import HacktoberThumbnail from './images/Hacktober Thumbnail.jpg';
import NVThumbnail from './images/New Vapor Thumbnail.png';
import P2PThumbnail from './images/Pause To Play Thumbnail.png';
import ScrollableThumbnail from './images/Scrollable Thumbnail.png';
import SDYJThumbnail from './images/Slam Dunk Your Junk Thumbnail.jpg';
import ShiftGenThumbnail from './images/ShiftGen Screenshot.png';
import SIKThumbail from './images/SIK Ghee.jpg';
import SnakeEyesThumbnail from './images/Snake Eyes Thumbnail.png';
import StensulThumbnail from './images/Stensul Component Library Screenshot.png';
import TDThumbnail from './images/Tennis Doctor Logo.jpg';
import UnsurvivorThumbnail from './images/Unsurvivor Thumbnail.png';
import InternetIcon from './icons/InternetIcon';
import CodeIcon from './icons/CodeIcon';
import InstagramIcon from './icons/InstagramIcon';
import ControllerIcon from './icons/ControllerIcon';
import DrawIcon from './icons/DrawIcon';
import ShopIcon from './icons/ShopIcon';

export const icons = {
  website: InternetIcon,
  code: CodeIcon,
  instagram: InstagramIcon,
  play: ControllerIcon,
  store: ShopIcon,
  design: DrawIcon,
};

const projects = [
  {
    title: 'EMAIL OPEN PROJECT',
    description:
      'In my second project with Stensul, an email marketing platform, I was asked to design and implement a honeypot web application for potential clients showing email opens in real-time based on tracking pixel requests.',
    thumbnail: EmailOpenProjectThumbnail,
    tags: ['design', 'web', 'brand', 'ui'],
    links: {
      website: 'https://emailopenproject.com/',
    },
  },
  {
    title: 'AUSTIN LYMPHATIC',
    description:
      'Austin Lymphatic wanted a redesign of their Wordpress website, and I delivered! This was a really fun branding project as well, and a good experience working with mobile.',
    thumbnail: AustinLymphaticThumbnail,
    tags: ['design', 'web', 'brand', 'ui'],
    links: {
      design:
        'https://www.figma.com/file/0GMHjponhksmxdcD14x3uK/Austin-Lymphatic?type=design&node-id=0%3A1&mode=design&t=b437wFOURn4d61al-1',
      website: 'https://austinlymph.com/',
    },
  },
  {
    title: 'SHIFTGEN',
    description:
      'This ER admin staff scheduling company wanted their popular app brought into the modern era! With the help of my visual design buddy Logan LaBo, we designed and implemented a sleek new interface!',
    thumbnail: ShiftGenThumbnail,
    tags: ['development', 'design', 'web', 'ui'],
    links: {
      design:
        'https://www.figma.com/file/6JfmCRFOuMwCAVpjji0lTQ/ShiftGen?type=design&node-id=436%3A29677&mode=design&t=Roq8OkXvGoaF7Jf3-1',
      website: 'https://www.shiftgen.com/',
    },
  },
  {
    title: 'STENSUL COMPONENT LIBRARY',
    description:
      'This enterprise-level email marketing tool was going through a major overhaul and needed some structure at the lowest level. I worked with the Stensul design and engineering teams to implement their new design system!',
    thumbnail: StensulThumbnail,
    tags: ['development', 'web', 'ui'],
    links: {
      design:
        'https://www.figma.com/file/6uafoTa6zkl5Pr6ftoZU7h/Stensul-DS-2.0',
      code: 'https://github.com/stensul/stui',
    },
  },
  {
    title: 'EBB & FLOW MASSAGE STUDIO',
    description:
      'For this amazing lymphatic drainage specialist, I designed a brand and logo, as well as created a landing page website with scheduling options using React, Tailwind, and Vercel.',
    thumbnail: EbbFlowThumbnail,
    tags: ['development', 'design', 'web', 'brand', 'logo', 'ui'],
    links: {
      website: 'https://www.ebbandflowmassagestudio.com/',
      code: 'https://github.com/recreationalcode/ebb-and-flow-web',
    },
  },
  {
    title: '(DIS)APPEAR AUDIO VISUALIZER',
    description:
      'I coded up a 3D audio visualizer with custom shaders for a bloom effect and a beat detection algorithm to introduce myself to the Processing framework using p5.js!',
    thumbnail: DisappearThumbnail,
    tags: ['development', 'design', 'web', 'animation', 'audio'],
    links: {
      instagram: 'https://www.instagram.com/p/CgtDltpMKea/',
      code: 'https://editor.p5js.org/recreationalcode/sketches/7c9x8idr3',
    },
  },
  {
    title: 'CRYSTAL',
    description:
      'In the Fractal F100 challege, I submitted a multiplayer base defense game made using Unity and Photon, encouraging players to coordinate and cooperate.',
    thumbnail: CrystalThumbnail,
    tags: ['development', 'design', 'game'],
    links: {
      play: 'https://shahman.itch.io/crystal',
      code: 'https://github.com/recreationalcode/crystal',
    },
  },
  {
    title: 'NEW VAPOR ANIMATION',
    description:
      'I wrote up this short 3D generative animation of a vaporwave-inspired city near the mountains to learn the flexibility and constraints of native web graphics with pure CSS!',
    thumbnail: NVThumbnail,
    tags: ['development', 'design', 'web', 'animation'],
    links: {
      instagram: 'https://www.instagram.com/p/CZfx_yRsqWX/',
      code: 'https://codepen.io/recreationalcoder/pen/bGrdwyQ',
    },
  },
  {
    title: 'SNAKE EYES',
    description:
      'During GMTK Game Jam 2022, I developed a risky dungeon puzzle game using Unity and some sweet free art. Try your luck!',
    thumbnail: SnakeEyesThumbnail,
    tags: ['development', 'design', 'game'],
    links: {
      play: 'https://shahman.itch.io/snake-eyes',
      code: 'https://github.com/recreationalcode/snake-eyes',
    },
  },
  {
    title: 'HACKTOBER',
    description:
      'For this spooky hack, I designed a logo that was used on digital and print media, including swag items like t-shirts and the coveted challenger coins.',
    thumbnail: HacktoberThumbnail,
    tags: ['design', 'logo'],
    links: {},
  },
  {
    title: 'SLAM DUNK YOUR JUNK',
    description:
      'I designed a basketball-inspired logo for this tidy event that was printed on some squeaky clean challenger coins!',
    thumbnail: SDYJThumbnail,
    tags: ['design', 'logo'],
    links: {},
  },
  {
    title: 'EARTHACK',
    description:
      'For this feel-good hack, I teamed up with @clayjuneau, @andrewwong, @michaeldain, and @akhilailla to visualize global carbon emissions, and also designed some logos!',
    thumbnail: EarthackThumbnail,
    tags: ['development', 'design', 'web'],
    links: {
      website: 'https://akhilailla.github.io/visualize-our-earth/',
    },
  },
  {
    title: 'SCROLLABLE',
    description:
      'I created a simple scroll shortcut Chrome extension to learn about the framework and scratch a usability itch at the same time!',
    thumbnail: ScrollableThumbnail,
    tags: ['development', 'design', 'web', 'ui'],
    links: {
      store:
        'https://chrome.google.com/webstore/detail/scrollable/dbplpiklfceceaonaaiemlkmcdmdpfkk',
      code: 'https://github.com/recreationalcode/scrollable',
      design: 'https://www.figma.com/file/wRaTW6EW3u5MkdnjoEiUq1/Scrollable',
    },
  },
  {
    title: 'TENNIS DOCTOR',
    description:
      'Brand, logo, and UI design for this prescriptive sports exercise regimen concept from the brilliant mind of @amishmenon.',
    thumbnail: TDThumbnail,
    tags: ['design', 'brand', 'mobile', 'ui'],
    links: {
      design:
        'https://www.figma.com/file/VvZpLkn3LNgufrAE3LHaB2/Tennis-Doctor---Neel',
    },
  },
  {
    title: 'UNSURVIVOR',
    description:
      'During GMTK Game Jam 2020, I developed an endless survival game where you play as a zombie facing an onslaught of human attackers.',
    thumbnail: UnsurvivorThumbnail,
    tags: ['development', 'design', 'game'],
    links: {
      play: 'https://shahman.itch.io/unsurvivor',
      code: 'https://github.com/recreationalcode/gmtk-game-jam-2020',
    },
  },
  {
    title: 'PAUSE TO PLAY',
    description:
      'I animated a stylish logo design (credit @ajolson) - coreographed to a Run-D.M.C. track - for a company hackthon using CSS and SVG.',
    thumbnail: P2PThumbnail,
    tags: ['development', 'design', 'web', 'animation', 'audio'],
    links: {
      code: 'https://codepen.io/recreationalcoder/pen/pogJGjV',
    },
  },
  {
    title: "SHIVY'S INDIAN KITCHEN",
    description:
      'I have provided various design and development work to this ghee-inspired company, including an e-commerce website using Bookmark as well as brand, logo, and cookbook designs.',
    thumbnail: SIKThumbail,
    tags: ['development', 'design', 'web', 'brand', 'logo', 'ui'],
    links: {
      website: 'https://www.shivysindiankitchen.com/',
    },
  },
  {
    title: 'FLOAT ON',
    description:
      'Along with @ianowen I coded up this Flappy Bird-esque side-scrolling mobile game, and in addition composed some gameplay music.',
    thumbnail: FloatOnThumbnail,
    tags: ['development', 'design', 'game', 'audio'],
    links: {
      store: 'https://www.gameloop.com/game/arcade/com.zateo.floaton',
    },
  },
];

export default projects;
