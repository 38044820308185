import { useEffect, useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import classNames from 'classnames';

import Hero from './Hero';
import DiscordIcon from './icons/DiscordIcon';
import Logo from './logos/Logo';
import FreelancersUnionBadge from './logos/FreelancersUnionBadge';
import Header from './Header';
import { PrimaryButton } from './Button';
import Project from './Project';
import projects from './projects';
import useWindowSize from './useWindowSize';
import useMediaQuery from './useMediaQuery';
import DiversityIcon from './icons/DiversityIcon';

function App() {
  const windowSize = useWindowSize();
  const isSm = useMediaQuery('(max-width: 640px)');
  const minimizedHeaderHeight = 64;
  const logoBaseSize = minimizedHeaderHeight * (isSm ? 2 : 4);

  const [isProjectView, setIsProjectView] = useState(false);

  const Home = () => (
    <div className={classNames('pb-24', 'bg-gray-dark', 'text-center')}>
      <Header
        windowHeight={windowSize.height}
        minimizedHeaderHeight={minimizedHeaderHeight}
        onClickPortfolio={() =>
          window.scrollTo(0, windowSize.height - minimizedHeaderHeight)
        }
      />
      <div
        className={classNames(
          'w-full space-y-8',
          'pb-2',
          'bg-gray-dark',
          'overflow-scroll',
          'font-title font-normal',
          'text-6xl text-gray-light text-center',
        )}
        style={{
          transform: `translateY(${minimizedHeaderHeight}px)`,
          paddingTop: `${windowSize.height}px`,
        }}>
        {projects.map((project) => (
          <Project
            key={project.title}
            isProjectView={isProjectView}
            setIsProjectView={setIsProjectView}
            thumbnail={project.thumbnail}
            title={project.title}
            description={project.description}
            tags={project.tags}
            links={project.links}
          />
        ))}
      </div>
      <div
        className={classNames(
          'mt-24',
          'font-title font-light',
          'text-2xl text-gray-light text-center',
        )}>
        by <span className={classNames('text-yellow')}>shahman</span>
      </div>
      <div
        className={classNames(
          'mt-2',
          'font-title font-light',
          'text-md text-gray-light text-center',
        )}>
        audio generated by{' '}
        <a
          href="https://elevenlabs.io/text-to-speech"
          className={classNames(
            'text-yellow hover:text-blue active:text-gray-light',
          )}>
          elevenlabs
        </a>
      </div>
    </div>
  );

  const Community = () => {
    const textRefs = useRef([]);
    const [containerWidth, setContainerWidth] = useState(120); // Default width

    useEffect(() => {
      const interval = setInterval(() => {
        const visibleText = textRefs.current.find(
          (ref) => ref && window.getComputedStyle(ref).opacity === '1',
        );
        if (visibleText) {
          setContainerWidth(visibleText.offsetWidth);
        }
      }, 50); // Check every 100ms to update width

      return () => clearInterval(interval);
    }, []);

    return (
      <div
        className={classNames(
          'bg-radial-gradient',
          'text-center',
          'w-full',
          'h-screen',
          'flex',
          'flex-col',
          'normal:justify-center',
          'items-center',
          'overflow-scroll',
        )}>
        <Hero />
        <div
          className={classNames(
            'justify-center',
            'items-center',
            'z-10',
            'w-4/5 sm:w-2/5 px-6 pb-8',
            'flex flex-col gap-8',
          )}>
          <div className={classNames('flex flex-col gap-3')}>
            <div
              className={classNames(
                'flex',
                'flex-col',
                'justify-center',
                'items-center',
              )}>
              <Logo
                logoBaseSize={logoBaseSize}
                className={classNames('origin-center animate-rotate', {})}
              />
              <div
                className={classNames(
                  'font-title font-normal',
                  'text-4xl sm:text-6xl text-gray-light text-center',
                  'flex flex-col',
                  'drop-shadow-lg',
                  {},
                )}>
                <div className="flex overflow-visible text-left">
                  <span>recreational</span>
                  <div
                    className="relative transition-width duration-200 ease-in-out"
                    style={{ width: `${containerWidth}px` }}>
                    {[
                      'coder',
                      'designer',
                      'copywriter',
                      'marketer',
                      'analyst',
                      'editor',
                      'translator',
                      'animator',
                      'accountant',
                      'consultant',
                      'photographer',
                      'assistant',
                    ].map((text, index) => (
                      <span
                        key={index}
                        ref={(el) => (textRefs.current[index] = el)}
                        className="absolute text-yellow animate-swap opacity-0"
                        style={{ animationDelay: `${index * 3}s` }}>
                        {text}
                      </span>
                    ))}
                  </div>
                </div>
                <span className="text-2xl sm:text-4xl font-light">
                  community
                </span>
              </div>
            </div>
            <div
              className={classNames(
                'bg-darkblue/60 backdrop-brightness-95',
                'px-4 py-6',
                'rounded-xl',
                'drop-shadow-md',
              )}>
              <div
                className={classNames(
                  'flex',
                  'flex-col',
                  'gap-2',
                  'justify-center',
                  'items-center',
                  'z-10',
                )}>
                <DiversityIcon size="h-6 sm:h-8 w-6 sm:w-8 pb-1" />
                <span className="text-md sm:text-xl text-gray-light font-body">
                  Network with like-minded individuals who understand the{' '}
                  <span className="font-title text-2xl text-yellow">
                    freelance
                  </span>{' '}
                  hustle.
                </span>
                <span className="text-sm sm:text-l text-gray-light font-body font-light">
                  Collaborate on projects, share advice, and build lasting
                  professional relationships in a community that feels like{' '}
                  <span className="font-title text-xl font-normal text-yellow">
                    home
                  </span>
                  .
                </span>
              </div>
            </div>
          </div>
          <PrimaryButton
            text="join the community"
            icon={DiscordIcon}
            link="https://discord.gg/X3xNYrJf84"
            isRound={isSm}
          />
          <a
            href="https://freelancersunion.org/"
            target="_blank"
            rel="noreferrer">
            <FreelancersUnionBadge
              size={logoBaseSize / 2}
              className={classNames('drop-shadow-sm wash')}
            />
          </a>
          <style jsx>{`
            .wash {
              filter: brightness(0) invert(1);
            }

            @keyframes textSwap {
              0% {
                opacity: 0;
                transform: translateY(0);
              }
              10%,
              100% {
                opacity: 0;
                transform: translateY(-20%) translateX(40%);
              }
              2%,
              8% {
                opacity: 1;
                transform: translateY(0);
              }
            }

            .animate-swap {
              animation: textSwap 36s infinite;
            }

            @keyframes rotate {
              100% {
                transform: rotate(360deg);
              }
            }

            .animate-rotate {
              animation: rotate 120s infinite;
            }
          `}</style>
        </div>
      </div>
    );
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/community" element={<Community />} />
      </Routes>
    </div>
  );
}

export default App;
