
function DownwardArrowIcon({ size = "h-4 w-4", fill = "fill-gray-light" }) {
  return (
    <svg className={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={fill}  d="M12 20.375L3.625 12L5.85 9.75L10.425 14.325V3.625H13.575V14.325L18.15 9.75L20.375 12L12 20.375Z" />
    </svg>
  );
}

export default DownwardArrowIcon;
